import React from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Table } from "antd";
import { render } from "@testing-library/react";

export default function ViewTranslationResult() {
  const { categoryID } = useParams();
  const [getResults, errorResults, loadingResults] = useAxios(
    `${process.env.REACT_APP_TRANSLATION_RESULTS_GET_API}${categoryID}`,
    "GET",
    "GET",
    ""
  );

  const columns = [
    {
      width: 100,
      title: "الاجابة الصحيحة",
      dataIndex: "correct_answer",
      render(_, record) {
        // Assuming correct_answer is an array of objects with a 'keyword' key
        return (
          <div className="text-3xl flex flex-col gap-2">
            {record?.correct_answer?.map((obj, index) => (
              <span key={index} className="mr-2">
                {obj.word}
              </span> // Access the 'keyword' key from the object
            ))}
          </div>
        );
      },
    },
    {
      width: 300,
      title: "اجابة الطالب",
      dataIndex: "student_answer",

      render(_, record) {
        const highlightDifferences = (correct, student) => {
          const correctWords = correct.split(" ");
          const studentWords = student.split(" ");

          const highlightedText = correctWords
            .map((correctWord, wordIndex) => {
              const studentWord = studentWords[wordIndex] || "";

              let highlightedWord = "";

              for (
                let i = 0;
                i < Math.max(correctWord.length, studentWord.length);
                i++
              ) {
                const correctChar = correctWord[i] || "";
                const studentChar = studentWord[i] || "_";

                if (correctChar === studentChar) {
                  // Matching characters are green
                  highlightedWord += `<span class="text-green-500">${studentChar}</span>`;
                } else {
                  // Mismatched characters show studentChar with correctChar above it
                  highlightedWord += `
                    <span class="relative inline-block " >
                      <span class="absolute -top-7 left-0 text-green-500">${correctChar}</span>
                      <span class="text-red-500">${studentChar}</span>
                    </span>
                  `;
                }
              }

              // Add space after the word
              return highlightedWord + "&nbsp;";
            })
            .join(""); // Words already have a space added, no extra needed

          return highlightedText;
        };

        return (
          <p
            className="text-3xl  "
            dangerouslySetInnerHTML={{
              __html: highlightDifferences(
                record.correct_answer[0].word,
                record.student_answer
              ),
            }}
          ></p>
        );
      },
    },
  ];
  return (
    <section className="min-h-screen w-full px-10 py-10 md:px-0 flex flex-col items-center justify-center gap-5">
      <p className="text-4xl font-bold">درجة الطالب</p>
      <p className="text-4xl font-bold">
        {" "}
        {getResults?.data?.student_degree} / {getResults?.data?.category_degree}{" "}
      </p>

      <Table
        dataSource={getResults?.data?.answers}
        columns={columns}
        loading={loadingResults}
        pagination={false}
        scroll={{ x: 600 }} // Enable horizontal scroll with a minimum width of 600px
        className="bg-white shadow-2xl border border-secondary rounded-2xl p-1 md:w-full w-1/2 mx-auto"
      />
    </section>
  );
}
