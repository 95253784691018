import { useState } from "react";
import QuestionModalAnswer from "./QuestionModalAnswer";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../MainComponents";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Table } from "antd";

const ModalAnswer = ({ exam, centerExam, homework, quiz }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const [showPopUpVideo, setShowPopUpVideo] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState("");
  const { ID } = useParams();
  const navigate = useNavigate();

  /* Translate Table */
  const Translatecolumns = [
    {
      width: 100,
      title: "الاجابة الصحيحة",
      dataIndex: "correct_answer",
      render(_, record) {
        // Assuming correct_answer is an array of objects with a 'keyword' key
        return (
          <div className="text-3xl flex flex-col gap-2">
            {record?.correct_answers?.map((obj, index) => (
              <span key={index} className="mr-2">
                {obj}
              </span> // Access the 'keyword' key from the object
            ))}
          </div>
        );
      },
    },
    {
      width: 200,
      title: "اجابة الطالب",
      dataIndex: "student_answer",

      render(_, record) {
        const highlightDifferences = (correct, student) => {
          const correctWords = correct.split(" ");
          const studentWords = student.split(" ");

          const highlightedText = correctWords
            .map((correctWord, wordIndex) => {
              const studentWord = studentWords[wordIndex] || "";

              let highlightedWord = "";

              for (
                let i = 0;
                i < Math.max(correctWord.length, studentWord.length);
                i++
              ) {
                const correctChar = correctWord[i] || "";
                const studentChar = studentWord[i] || "_";

                if (correctChar === studentChar) {
                  // Matching characters are green
                  highlightedWord += `<span class="text-green-500">${studentChar}</span>`;
                } else {
                  // Mismatched characters show studentChar with correctChar above it
                  highlightedWord += `
                      <span class="relative inline-block " >
                        <span class="absolute -top-7 left-0 text-green-500">${correctChar}</span>
                        <span class="text-red-500">${studentChar}</span>
                      </span>
                    `;
                }
              }

              // Add space after the word
              return highlightedWord + "&nbsp;";
            })
            .join(""); // Words already have a space added, no extra needed

          return highlightedText;
        };

        return (
          <p
            className="text-3xl  "
            dangerouslySetInnerHTML={{
              __html: highlightDifferences(
                record.correct_answers[0],
                record.student_answer
              ),
            }}
          ></p>
        );
      },
    },
    {
      width: 100,
      title: "درجة سؤال",
      dataIndex: "degree",
    },
  ];

  const api = centerExam
    ? process.env.REACT_APP_GET_MODELANSWER_CENTER_STUDNET_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_MODAL_ANSWER_API
    : homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_MODAL_ANSWER_API
    : process.env.REACT_APP_STUDENT_QUIZZ_MODAL_ANSWER_API;

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}/${ID}`,
    "GET",
    "GET",
    ""
  );

  function handleOnClickQuestion(id) {
    handelOpenCollapse();
    setActiveQuestionId(id);
  }

  function handelOpenCollapse() {
    setCollapse(true);
  }

  function handelCloseCollapse() {
    setCollapse(false);
  }

  return (
    <div className="left-response-container flex flex-col gap-4 items-start w-full">
      <HelmetTags title="نموذج الإجابة | Sir karam makram"></HelmetTags>
      <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={handelCloseCollapse}
        activeQuestionId={activeQuestionId}
        question={modalAnswerData?.data?.questions[activeQuestionId]}
        setCurrentVideoSrc={setCurrentVideoSrc}
        setShowPopUpVideo={setShowPopUpVideo}
      />
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        {modalAnswerLoading && <Loader />}
        {modalAnswerErrors && (
          <div className="flex justify-center flex-col">
            <p>{modalAnswerErrors}</p>
            <button
              onClick={() => navigate(-1)}
              className="flex justify-center my-6"
            >
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110`}
                  icon={faChevronRight}
                />
              </div>
            </button>
          </div>
        )}
        {!modalAnswerLoading && !modalAnswerErrors && (
          <>
            <h1 className="text-[20px] font-bold text-secondary dark:text-white ">
              نموذج الإجابة
            </h1>
            <p className="my-[20px] text-center text-secondary dark:text-white">
              اضغط على رقم السؤال لترى إجابته
            </p>

            <div className="response-questions myactual-questions-container my-[40px] w-1/2">
              {modalAnswerData?.data?.questions?.map((question, index) => (
                <div
                  key={index}
                  onClick={() => handleOnClickQuestion(index)}
                  className={`single-ques-square  mb-[4px] flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-md text-[18px] font-bold text-[#fff] ${
                    question.is_correct ? "bg-lime-500" : "bg-secondary"
                  }  `}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <div className="indicators flex w-full items-center justify-center gap-[20px] border-t-2 border-t-light py-[20px] sm:flex-col">
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-lime-500">
                <FontAwesomeIcon icon={faCircleCheck} />
                <p>اجابة صحيحة</p>
              </div>
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-secondary">
                <FontAwesomeIcon icon={faCircleXmark} /> <p>إجابة خاطئة</p>
              </div>
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-amber-400">
                <FontAwesomeIcon icon={faSpinner} spinPulse />{" "}
                <p>في انتظار التصحيح</p>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Translate  */}
      {modalAnswerData?.data?.translation_questions?.length > 0 && (
        <div className="w-full px-10 py-10 md:px-0 flex flex-col items-center justify-center gap-5">
          <Table
            dataSource={modalAnswerData?.data?.translation_questions}
            columns={Translatecolumns}
            loading={modalAnswerLoading}
            pagination={false}
            scroll={{ x: 600 }}
            className="bg-white shadow-2xl border border-secondary rounded-2xl p-1 md:w-full w-1/2 mx-auto"
          />
        </div>
      )}
    </div>
    // <></>
  );
};

export default ModalAnswer;
