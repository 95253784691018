import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useReducer, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

function AdminSingleTranslation({ edit }) {
  const AuthState = useAuthState();
  const { translationID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath(
    location.pathname,
    "/admin/translations/edit/:translationID"
  );
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditExam": {
        return {
          ...state,
          submitAddEditExam: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditExam: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_TRANSLATION_WORD_TAB_API
    : process.env.REACT_APP_ADMIN_ADD_TRANSLATION_WORD_TAB_API;

  //!--------- add edit exam -------
  const [ExamAddEditSuccess, ExamAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditExam.flag,
    state.submitAddEditExam.dependency,
    state.submitAddEditExam.data,
    true
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onTouched",
    values: { degree: 1 },
  });

  const { fields, append, remove } = useFieldArray({
    name: "english_words",
    control,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({}); // Ensure at least one field is present
    }
  }, [append, fields.length]);

  const handleWordChange = (value, index) => {
    setValue(`english_words[${index}]`, value); // Directly setting the value of the field
  };
  const onSubmit = (data) => {
    const finalData = edit
      ? {
          ...data,
          id: translationID,
          english_words: data.english_words.map((word) => word.toLowerCase()),
        }
      : {
          ...data,
          translation_category_id: translationID,
          english_words: data.english_words.map((word) => word.toLowerCase()),
        };
    console.log(finalData);
    dispatch({
      type: "setSubmitAddEditExam",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditExam.dependency,
        data: finalData,
      },
    });
  };
  console.log(match);

  useEffect(() => {
    if (ExamAddEditSuccess) {
      reset();
    }
    if (edit && ExamAddEditSuccess) {
      navigate(-1, { replace: true });
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ExamAddEditSuccess]);

  const table = useTable(
    "translationsWords",
    "",
    "",
    ExamAddEditSuccess,
    "",
    translationID
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الترجمه | Sir karam makram"></HelmetTags>

      <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
        <HelmetTags title="الترجمات | Sir karam makram"></HelmetTags>
        <div className="form-container my-20 flex w-full flex-col items-center">
          <h2 className="my-20 text-center text-3xl font-semibold">
            من فضلك قم بملئ البيانات لإضافة الكلمه
          </h2>

          <form
            encType="multipart/form-data"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="cardS1 form md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10"
          >
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label htmlFor="arabic_word">الكلمه بالعربي</label>
                <input
                  className="input"
                  type="text"
                  id="arabic_word"
                  placeholder="الكلمه بالعربي"
                  name="arabic_word"
                  autoComplete="on"
                  {...register("arabic_word", {
                    required: true,
                    maxLength: 40,
                    minLength: 3,
                  })}
                />
                {errors.arabic_word && (
                  <p classarabic_word="w-full text-end text-[12px] text-red-900  ">
                    {errors.arabic_word.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.arabic_word.type === "maxLength" &&
                      "أقصي عدد للحروف هو ٤٠ حرف"}
                    {errors.arabic_word.type === "minLength" &&
                      "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.arabic_word && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.arabic_word[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="name flex w-full flex-col items-start justify-center gap-2 md:w-full">
                <label>الكلمات بالانجليزي</label>
                {fields.map((word, index) => (
                  <div key={index} className="flex items-center gap-2 w-full">
                    <input
                      className="input"
                      type="text"
                      placeholder={`الكلمة ${index + 1}`}
                      required
                      onChange={(e) => handleWordChange(e.target.value, index)}
                    />
                    {fields.length === 1 ? null : (
                      <button
                        type="button"
                        className="text-white bg-red-500 px-4 py-3 rounded-full "
                        onClick={() => remove(index)}
                        disabled={fields.length === 1}
                      >
                        X
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="text-white border border-secondary hover:bg-light duration-200 hover:text-secondary px-4 py-3 bg-secondary rounded-3xl"
                  onClick={() => append({})}
                >
                  + أضف كلمة أخرى
                </button>

                {/* Errors */}
                {errors.english_words && (
                  <p className="w-full text-end text-[12px] text-red-900">
                    {errors.english_words.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.english_words.type === "maxLength" &&
                      "أقصي عدد للحروف هو ٤٠ حرف"}
                    {errors.english_words.type === "minLength" &&
                      "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}

                {/* Server Errors */}
                {ExamAddEditErrors?.response?.data?.errors?.english_words && (
                  <p className="w-full text-end text-[12px] text-red-900">
                    {ExamAddEditErrors.response.data.errors.english_words[0]}
                  </p>
                )}
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
              <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
                <label htmlFor="degree">الدرجه</label>
                <input
                  className="input"
                  type="number"
                  id="degree"
                  placeholder="الدرجه"
                  name="degree"
                  min={1}
                  autoComplete="on"
                  {...register("degree", {
                    required: true,
                    maxLength: 40,
                    minLength: 1,
                  })}
                />
                {errors.degree && (
                  <p classdegree="w-full text-end text-[12px] text-red-900  ">
                    {errors.degree.type === "required" && "برجاء ملئ هذا الحقل"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.degree && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.degree[0]}
                      </p>
                    )
                }
              </div>
            </div>
            {/** submit */}

            <button
              disabled={
                !isValid || (submitLoading && state.submitAddEditExam.flag)
              }
              className=" login-button mt-6 w-full "
              type="submit"
            >
              {submitLoading && state.submitAddEditExam.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <p>اضافة كلمه</p>
              )}
            </button>
          </form>
        </div>
      </section>

      {/**
       * //!---------Table-----
       */}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_view")
        )) &&
        !edit && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default AdminSingleTranslation;
