import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import useCountDown from "./Hooks/useCountDown";

function SingleCourse({
  lecture,
  admin,
  exam,
  reFetch,
  setReFetch,
  notSigned,
  isExam,
  course,
  translation,
  studentsLink,
  isDone,
}) {
  const { days, hours, minutes, seconds } = useCountDown(lecture.expire_at);
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  //!--------- delete Lecture -------
  const apiDelete = exam
    ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
    : course
    ? process.env.REACT_APP_ADMIN_DELETE_COURSE_API
    : translation
    ? process.env.REACT_APP_ADMIN_DELETE_TRANSLATION_TAB_API
    : process.env.REACT_APP_ADMIN_DELETE_LECTURE_API;

  const [deleteLectureSuccess, deleteLectureErrors, deleteloading] = useAxios(
    apiDelete,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteLecture(id) {
    setSubmitDelete({
      flag: "deleteLecture",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteLectureSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteLectureSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-8"
    >
      <div class="min-h-[350px] w-[350px] bg-white shadow-lg sm:w-[300px] relative flex w-80 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
        <div class="relative mx-4 -mt-8 h-[240px] overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
          <img
            onClick={() =>
              navigate(
                `${
                  notSigned
                    ? "/signin"
                    : admin
                    ? `${
                        exam
                          ? `/admin/exams/${lecture?.key}`
                          : translation
                          ? `/admin/translations/${lecture?.key}`
                          : `/admin/lectures/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : isDone
                          ? `/home/translation/results/${lecture?.key}`
                          : translation
                          ? `/home/translation/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
                }`
              )
            }
            className="h-[240px] w-full object-cover  duration-300 hover:scale-125"
            src={lecture?.img}
            alt={lecture?.title}
          />
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_show_creator")
            )) &&
          admin &&
          !exam &&
          !translation ? (
            <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold ">
              {lecture?.created_by && `By ${lecture?.created_by} `}
            </div>
          ) : null}
        </div>
        <div class="p-6">
          <h3
            onClick={() =>
              navigate(
                `${
                  notSigned
                    ? "/signin"
                    : admin
                    ? `${
                        exam
                          ? `/admin/exams/${lecture?.key}`
                          : translation
                          ? `/admin/translations/${lecture?.key}`
                          : `/admin/lectures/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : isDone
                          ? `/home/translation/results/${lecture?.key}`
                          : translation
                          ? `/home/translation/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
                }`
              )
            }
            className={`w-full title mb-7 w-fit cursor-pointer text-secondary ${
              admin ? "text-end" : "text-center"
            }   text-2xl  font-bold`}
          >
            {lecture?.title?.substring(0, 33)}
          </h3>
          <p
            dir="rtl"
            className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70"
          >
            {lecture?.description?.substring(0, 70)}...
          </p>
        </div>
        <div class="p-6 pt-0">
          {/* {!admin && !exam && (
            <>
              {lecture.is_buy === 1 && (
                <div className="w-full font-bold py-2 border-t-2 border-b-2 border-secondary rounded-md mt-4">
                  <p className="mb-2">Expire After </p>
                  <p className="text-center text-2xl">
                    {days} Days | {hours} Hours | {minutes} Miniutes | {seconds}
                    Seconds
                  </p>
                </div>
              )}
            </>
          )} */}

          <div
            className={`visibility-cta mt-5 flex w-full   ${
              admin ? "justify-between" : "justify-center"
            } items-center pb-4 pt-12`}
          >
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture")
              )) &&
              admin && (
                <div className="visibility pointer-events-none flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                  {lecture?.visibility === 0 ? "مخفي" : "مرئي"}
                </div>
              )}
            {admin ? (
              <div className="admin-options flex w-full justify-end gap-5">
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(
                      `${exam ? "exam_delete" : "lecture_delete"}`
                    )
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: loading }}
                    onConfirm={() => handleDeleteLecture(lecture?.key)}
                    title={"هل تريد الحذف؟"}
                  >
                    <button className="w-24 rounded-2xl border-2  border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      حذف
                    </button>
                  </Popconfirm>
                )}
                {exam || course ? (
                  <Link
                    to={
                      studentsLink
                        ? studentsLink
                        : isExam
                        ? `/admin/exams/all-students/${lecture?.key}`
                        : course
                        ? `/admin/courses/all-students/${lecture?.key}`
                        : `/admin/quizzes/all-students/${lecture?.key}`
                    }
                    className="rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    الطلاب
                  </Link>
                ) : null}

                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(
                      `${exam ? "exam_edit" : "lecture_edit"}`
                    )
                  )) &&
                  admin && (
                    <Link
                      to={`${
                        exam
                          ? `/admin/quizzes/edit-quiz/${lecture?.key}`
                          : isDone
                          ? `/home/translations/results/${lecture?.key}`
                          : translation
                          ? `/admin/translations/edit-translation/${lecture?.key}`
                          : `/admin/lectures/edit-lecture/${lecture?.key}`
                      }`}
                      className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    >
                      تعديل
                    </Link>
                  )}
              </div>
            ) : (
              <Link
                to={
                  notSigned
                    ? "/signin"
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : isDone
                          ? `/home/translation/results/${lecture?.key}`
                          : translation
                          ? `/home/translation/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
                }
                className="buttonS1 w-full text-center bg-secondary text-white py-6 rounded-xl border-2 border-secondary hover:bg-white hover:text-secondary transition-all"
              >
                {exam
                  ? " مشاهده الامتحان"
                  : isDone
                  ? "الاجابات"
                  : translation
                  ? "مشاهده ترجمة"
                  : "مشاهده الحصة"}
              </Link>
            )}
          </div>
        </div>
      </div>
      {/* <div className="img relative h-[170px] w-full cursor-pointer overflow-hidden">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `${
                        exam
                          ? `/admin/quizzes/${lecture?.key}`
                          : `/admin/lectures/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
              }`
            )
          }
          className="h-full w-full object-cover  duration-300 hover:scale-125"
          src={lecture?.img}
          alt={lecture?.title}
        />
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_show_creator")
          )) &&
          admin &&
          !exam && (
            <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold ">
              {lecture?.created_by && `By ${lecture?.created_by} `}
            </div>
          )}
      </div>
      <div className="course-details p-8 mt-4 flex flex-col items-center text-center">
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `${
                        exam
                          ? `/admin/quizzes/${lecture?.key}`
                          : `/admin/lectures/sessions/${lecture?.key}`
                      }`
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
              }`
            )
          }
          className={`title mb-7 w-fit cursor-pointer text-secondary ${
            admin ? "text-end" : "text-center"
          }   text-2xl  font-bold`}
        >
          {lecture?.title?.substring(0, 33)}
        </h3>
        <p
          dir="ltr"
          className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70"
        >
          {lecture?.description?.substring(0, 70)}...
        </p>

        {!admin && !exam && (
          <>
            {lecture.is_buy === 1 && (
              <div className="w-full font-bold py-2 border-t-2 border-b-2 border-secondary rounded-md mt-4">
                <p className="mb-2">Expire After </p>
                <p className="text-center text-2xl">
                  {days} Days | {hours} Hours | {minutes} Miniutes | {seconds}
                  Seconds
                </p>
              </div>
            )}
          </>
        )}

        <div
          className={`visibility-cta mt-5 flex w-full   ${
            admin ? "justify-between" : "justify-center"
          } items-center pb-4 pt-12`}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture")
            )) &&
            admin && (
              <div className="visibility pointer-events-none flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                {lecture?.visibility === 0 ? "Hidden" : "Visible"}
              </div>
            )}
          {admin ? (
            <div className="admin-options flex w-full justify-end gap-5">
              {exam && (
                <Link
                  to={`/admin/quizzes/all-students/${lecture?.key}`}
                  className="rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                >
                  Students
                </Link>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_edit" : "lecture_edit"}`
                  )
                )) &&
                admin && (
                  <Link
                    to={`${
                      exam
                        ? `/admin/quizzes/edit-quiz/${lecture?.key}`
                        : `/admin/lectures/edit-lecture/${lecture?.key}`
                    }`}
                    className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    Edit
                  </Link>
                )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_delete" : "lecture_delete"}`
                  )
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteLecture(lecture?.key)}
                  title={
                    exam
                      ? "Do you really want to delete the Exam?"
                      : "Do you really want to delete the lecture?"
                  }
                >
                  <button className="w-24 rounded-2xl border-2  border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}
            </div>
          ) : (
            <div className="flex justify-between w-full items-center">
              <Link
                to={
                  notSigned
                    ? "/signin"
                    : `${
                        exam
                          ? `/home/exams/${lecture?.key}`
                          : `/home/lectures/sessions/${lecture?.key}`
                      }`
                }
                className="buttonS1"
              >
                {lecture.is_buy === 1
                  ? `${exam ? "View Exam" : "View Lecture"}`
                  : "Enroll Now"}
              </Link>
              <div className="stage-wrapper flex h-10 w-fit items-center justify-center gap-1  rounded-xl bg-light px-3  font-semibold">
                <p className="stage flex flex-row-reverse gap-1 text-xl font-semibold">
                  {lecture?.price === 0 || lecture?.points === 0 ? (
                    <span>Free</span>
                  ) : (
                    <>
                      {" "}
                      <span>EGP</span>
                      <span>{lecture?.price} </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </div> */}
    </motion.div>
  );
}

export default SingleCourse;
