import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";

function TranslationsTable({ id, tableFor }) {
  const [data, setData] = useState([]);

  let translationapi =
    tableFor === "homeworkTranslations"
      ? `${process.env.REACT_APP_ADMIN_HOMEWORK_SETS_API}${id}`
      : tableFor === "quizTranslations"
      ? `${process.env.REACT_APP_ADMIN_QUIZ_SETS_API}${id}`
      : `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${id}`;

  const [getTransalationData, errorTransalationData, loadingTransalationData] =
    useAxios(translationapi, "GET", "GET");

  useEffect(() => {
    setData(getTransalationData?.data?.translation_set);
  }, [getTransalationData]);

  const table = useTable(tableFor, "", "", "", "", "", true, data);

  return <div className="h-fit w-full">{table}</div>;
}

export default TranslationsTable;
