import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";

function AddTranslate({ type, id, refetch, setRefetch }) {
  const { lectureID, examID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddTranslates": {
        return {
          ...state,
          submitAddTranslates: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddTranslates: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm({
    mode: "onTouched",
  });

  //!---------Translation Data
  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRANSLATION_TAB_API,
    "GET",
    "GET"
  );
  //!--------- add questions to  quiz / homework   -------

  let addTranslateApi =
    type === "homework"
      ? process.env.REACT_APP_HOMEWORK_ADD_SETS_TRANSLATION_API
      : type === "exam"
      ? process.env.REACT_APP_EXAM_ADD_SETS_TRANSLATION_API
      : process.env.REACT_APP_QUIZ_ADD_SETS_TRANSLATION_API;

  const [AddSetsSuccess, AddSetsErrors, submitLoading] = useAxios(
    addTranslateApi,
    "POST",
    state.submitAddTranslates.flag,
    state.submitAddTranslates.dependency,
    state.submitAddTranslates.data,
    true
  );
  useEffect(() => {
    //!---add actions ----
    if (AddSetsSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [AddSetsSuccess]);

  const onSubmitAddSets = (data) => {
    let finalData;
    const transformedData = {
      ...data,
      is_random: data.is_random ? 1 : 0, // Transform boolean to number
    };
    if (type === "homework") {
      finalData = {
        homework_id: id.toString(),
        is_random: +data?.is_random,
        lecture_id: lectureID,
        ...transformedData,
      };
    }

    if (type === "quiz") {
      finalData = {
        quiz_id: id.toString(),
        is_random: +data?.is_random,
        lecture_id: lectureID,
        ...transformedData,
      };
    }

    if (type === "exam") {
      finalData = {
        exam_id: examID,
        is_random: +data.is_random,
        lecture_id: lectureID,
        ...transformedData,
      };
    }

    dispatch({
      type: "setSubmitAddTranslates",
      payload: {
        flag: "Quiz",
        dependency: !state.submitAddTranslates.dependency,
        data: finalData,
      },
    });
  };

  return (
    <div className="cardS1 w-full">
      {/**
       * //!--- add questions to the exam -------
       */}
      <form
        method="post"
        onSubmit={handleSubmit(onSubmitAddSets)}
        className="form"
      >
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:items-center md:gap-10 ">
          <div className=" flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="medium">
              اختار الترجمة
            </label>
            <select className="input" {...register(`translation_category_id`)}>
              {allExamsData?.data?.map((item, index) => (
                <option key={item.key} value={item.key}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className=" medium flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="medium">
              عدد الكلمات{" "}
            </label>
            <input
              className="input"
              type="number"
              min={0}
              id="words_num"
              placeholder="عدد الكلمات"
              name="words_num"
              autoComplete="on"
              {...register(`words_num`)}
            />
          </div>
          <div className="flex w-full items-center justify-end gap-10">
            <label className="visibility-switch">
              <input
                className=""
                id="is_random"
                name="is_random"
                {...register("is_random", {
                  required: false,
                })}
                type="checkbox"
              />
              <span className="visibility-slider">
                <svg
                  className="slider-icon"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path fill="none" d="m4 16.5 8 8 16-16"></path>
                </svg>
              </span>
            </label>
            <div>
              <label
                className="w-full cursor-pointer truncate"
                htmlFor="is_random"
              >
                Random
              </label>
            </div>
          </div>
        </div>

        {/** submit */}

        <button
          disabled={
            !isValid || (submitLoading && state.submitAddTranslates.flag)
          }
          className=" login-button  mt-6 w-full "
          type="submit"
        >
          {submitLoading && state.submitAddTranslates.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "اضافة ترجمة"
          )}
        </button>
      </form>
    </div>
  );
}

export default AddTranslate;
