import { useEffect, useState, useMemo } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";
import { use } from "react";

function AdminLectures() {
  const [filterStage, setFilterStage] = useState(() => {
    const savedFilterStage = localStorage.getItem("filterStage");
    return savedFilterStage ? JSON.parse(savedFilterStage) : null;
  });

  const [filterType, setFilterType] = useState(1);

  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [filterlecture, setFilterLecture] = useState([]);
  const [allLecturesData, , allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  useEffect(() => {
    localStorage.setItem("filterStage", JSON.stringify(filterStage));
  }, [filterStage]);

  useEffect(() => {
    const filteredLectures = allLecturesData?.data?.filter((lecture) => {
      if (filterStage === null) return true;
      if (filterStage === 3) return lecture.stage === filterStage;
      if (filterType === null) return lecture.stage === filterStage;
      return lecture.stage === filterStage && lecture.is_general === filterType;
    });

    console.log("Filtered Lectures:", filteredLectures);
    setFilterLecture(filteredLectures || []);
  }, [allLecturesData, filterStage, filterType]);

  return (
    <section className="h-auto w-full px-20 py-20 dark:bg-dark dark:text-light">
      <HelmetTags title="المحاضرات | Sir karam makram" />
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          المحاضرات
        </h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <div className="flex w-full justify-end gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="ml-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary active:scale-90"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              اضافة محاضرة
            </button>
          </div>
        )}
        <div className="flex w-full flex-row-reverse gap-10 md:justify-evenly">
          {["الاول", "الثاني", "الثالث"].map((stage, index) => (
            <button
              key={stage}
              onClick={() => {
                setFilterStage(index + 1); // Update stage
                if (filterStage === 3) {
                  setFilterType(null);
                }
                setFilterType(1); // Reset the filter type when changing stage
              }}
              className={`${
                filterStage === index + 1
                  ? "bg-secondary text-light"
                  : "border-secondary bg-light"
              } ml-4 rounded-xl border-2 px-2 py-1 font-semibold transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90`}
            >
              {`الصف ${stage}`}
            </button>
          ))}
        </div>

        {filterStage !== null &&
          filterStage !== 3 && ( // Only show type filters if a stage is selected
            <div className="flex w-full flex-row-reverse gap-10 md:justify-evenly mt-4">
              <button
                onClick={() => setFilterType(1)}
                className={`${
                  filterType === 1
                    ? "bg-secondary text-light"
                    : "border-secondary bg-light"
                } ml-4 rounded-xl border-2 px-2 py-1 font-semibold transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90`}
              >
                عام
              </button>
              <button
                onClick={() => setFilterType(0)}
                className={`${
                  filterType === 0
                    ? "bg-secondary text-light"
                    : "border-secondary bg-light"
                } ml-4 rounded-xl border-2 px-2 py-1 font-semibold transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90`}
              >
                تجريبي
              </button>
            </div>
          )}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {filterlecture?.length === 0 ? (
            <p>لا يوجد محاضرات</p>
          ) : (
            filterlecture?.map((Card) => {
              if (Card.is_chapter) {
                return (
                  <SingleChapter
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    chapter={Card}
                    key={Card.key}
                  />
                );
              } else {
                return (
                  <SingleCourse
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    lecture={Card}
                    key={Card.key}
                  />
                );
              }
            })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
