import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";

function SetsTable({ id, tableFor }) {
  let api =
    tableFor === "homeworkSets"
      ? `${process.env.REACT_APP_ADMIN_HOMEWORK_SETS_API}${id}`
      : tableFor === "quizSets"
      ? `${process.env.REACT_APP_ADMIN_QUIZ_SETS_API}${id}`
      : `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${id}`;

  const [getData, errorData, loadingData] = useAxios(api, "GET", "GET");

  const table = useTable(
    tableFor,
    "",
    "",
    "",
    "",
    "",
    true,
    getData?.data?.sets
  );

  return <div className="h-fit w-full">{table}</div>;
}

export default SetsTable;
