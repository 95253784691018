import { useRef, useState } from "react";

import HelmetTags from "../../../MainComponents/HelmetTags";
import { Table } from "antd";
import useTable from "../../../MainComponents/Hooks/useTable";
import SingleCourse from "../../../MainComponents/SingleCourse";
import { Loader } from "../../../MainComponents";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useFade from "../../../MainComponents/Hooks/useFade";

function Translation() {
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);
  const [reFetch, setReFetch] = useState(false);
  const [allCategoriesData, allCategoriesErrors, allCategoriesloading] =
    useAxios(process.env.REACT_APP_TRANSLATION_GET_API, "GET", "GET", reFetch);
  if (allCategoriesloading) {
    return <Loader />;
  }
  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light ">
      <HelmetTags title=" ترجمه الكلمات | Sir karam makram"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          }`}
        >
         تسميع الكلمات 
        </h2>
      </div>

      <div className="lectures grid-auto-fit w-full">
        {allCategoriesData?.data?.length === 0 ? (
          <p>لا يوجد ترجمات</p>
        ) : (
          allCategoriesData?.data?.map((Card) => (
            <SingleCourse
              reFetch={reFetch}
              setReFetch={setReFetch}
              translation
              isExam
              lecture={Card}
              key={Card.key}
              isDone={Card.is_done}
            />
          ))
        )}
      </div>
    </section>
  );
}

export default Translation;
