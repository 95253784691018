import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const Question = ({ state, dispatch, homework, exam, centerExam, index }) => {
  const { ID } = useParams();

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
    : centerExam
    ? process.env.REACT_APP_UPDATE_QUESTION_BANCK_STUDNET_QUESTION_ANSWER_API
    : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = async (answer, questionId, n) => {
    // Optimistic update for instant UI feedback
    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n, isOptimistic: true },
    });

    try {
      // Make API call
      const response = await axios.post(checkApi, {
        id: questionId,
        order: answer,
        lecture_id: centerExam ? null : ID,
        quiz_id: centerExam ? ID : null,
        n: n,
      });

      // If successful, update answered questions
      dispatch({
        type: "setAnsweredQuestions",
        payload: { id: questionId, order: answer, n },
      });

      // Confirm the selection with final state update
      dispatch({
        type: "setIsSelectedAnswer",
        payload: { questionId, answer, n, isOptimistic: false },
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.message || "Failed to save answer");

      // Revert the selection on error
      dispatch({
        type: "setIsSelectedAnswer",
        payload: { questionId, answer: null, n, isOptimistic: false },
      });
    }
  };

  const isParagraph = state.questions[index]?.paragraph;

  return (
    <>
      {isParagraph ? (
        <div className="flex flex-col gap-10 w-full justify-center items-center">
          <p className="w-full text-4xl py-7 ">
            {state.questions[index].paragraph}
          </p>
          <div className="w-full flex flex-col ">
            {state.questions[index].questions?.map((question, index) => (
              <div className="container max-w-[600px]  border-b-2 border-secondary pb-10 mb-4  ">
                <div className="current-question ">
                  {`${index + 1}.`}
                  <div
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html: question?.question,
                    }}
                  />
                </div>

                <div className="current-question-degree">
                  <p> درجه {question?.degree} </p>
                </div>

                <div className="current-question-answers">
                  {question?.answers?.map((answer, i) => (
                    <div
                      key={i}
                      className={`${
                        question.selected_answer === answer.order && "selected"
                      }`}
                      onClick={() =>
                        handleSelectAnswer(
                          answer.order,
                          question.id,
                          question.n
                        )
                      }
                    >
                      <span>{answer.order} </span>
                      <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container max-w-[600px]    ">
          <div className="current-question ">
            {`${index + 1}.`}
            <div
              dangerouslySetInnerHTML={{
                __html: state.questions[index]?.question,
              }}
            />
          </div>

          <div className="current-question-degree">
            <p> درجه {state.questions[index]?.degree} </p>
          </div>

          <div className="current-question-answers">
            {state.questions[index]?.answers?.map((answer, i) => (
              <div
                key={i}
                className={`${
                  state.questions[index].selected_answer === answer.order &&
                  "selected"
                }`}
                onClick={() =>
                  handleSelectAnswer(
                    answer.order,
                    state.questions[index].id,
                    state.questions[index].n
                  )
                }
              >
                <span>{answer.order} </span>
                <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Question;
