import React, { useState, useEffect, useReducer } from "react";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Input, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import toast from "react-hot-toast";
import { Loader } from "../../../MainComponents";
import QuizTimer from "../QuizNew/component/quizHeader/QuizTimer";
function reducer(state, action) {
  switch (action.type) {
    case "setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case "setQuizInfo": {
      return {
        ...state,
        quizInfo: action.payload,
      };
    }
    case "setData": {
      return {
        ...state,
        data: action.payload,
      };
    }
    case "showTimer": {
      return {
        ...state,
        showTimer: action.payload,
      };
    }

    default:
      throw Error("Unknown action: " + action.type);
  }
}
const initialState = {
  loading: true,
  quizInfo: {},
  data: [],
  showTimer: false,
};

export default function ViewTranslation() {
  const { categoryID } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [isValid, setIsValid] = useState({});
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [DataTable, ErrorTable, LoadingTable] = useAxios(
    `${process.env.REACT_APP_TRANSLATION_ID_GET_API}${categoryID}`,
    "GET",
    "GET"
  );

  const [successSubmit, ErrorSubmit, LoadingSubmit] = useAxios(
    process.env.REACT_APP_TRANSLATION_SUMBIT_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  console.log(state);

  useEffect(() => {
    if (!LoadingTable) {
      if (DataTable?.data === undefined) {
        navigate(-1);
      }
    }
    dispatch({
      type: "setQuizInfo",
      payload: DataTable
        ? { duration: DataTable?.data?.duration || 0, title: "translate" }
        : { duration: 0 },
    });
    dispatch({
      type: "setData",
      payload: DataTable ? DataTable?.data?.translations : [],
    });
    dispatch({
      type: "showTimer",
      payload: true,
    });
  }, [DataTable, LoadingTable, navigate]);

  const onSubmit = () => {
    setSubmitAdd({
      flag: "Add Translate",
      depedency: !submitAdd.dependency,
      data: {
        category_id: categoryID,
        answers: Object.entries(inputValues).map(([key, value]) => ({
          key,
          answer: value?.toLowerCase(),
        })),
      },
    });
  };

  useEffect(() => {
    Object.entries(isValid).forEach(([key, valid]) => {
      if (valid === false) {
        toast.error(`يُسمح فقط بإدخال الحروف `);
      }
    });
  }, [isValid]);

  const handleInputChange = (value, key) => {
    const containsNumbers = /\d/.test(value);
    setInputValues((prev) => ({
      ...prev,
      [key]: value,
    }));
    setIsValid((prev) => ({ ...prev, [key]: !containsNumbers }));
  };

  const Resultcolumns = [
    {
      width: 100,
      title: "الكلمة",
      dataIndex: "arabic_word",
      render(_, record) {
        return <p className="text-3xl">{record.arabic_word}</p>;
      },
    },
    {
      width: 100,
      title: "الاجابة طالب",
      dataIndex: "student_answer",
      render(_, record) {
        return (
          <p
            className={`text-3xl ${
              record.is_wrong === 1 ? "text-red-500" : "text-green-500"
            }`}
          >
            {record.student_answer}
          </p>
        );
      },
    },
  ];

  const Translationcolumns = [
    {
      width: 100,
      title: "الترجمة",
      key: "translation",
      align: "right",
      render: (_, record) => (
        <Input
          value={inputValues[record.key] || ""}
          onChange={(e) => handleInputChange(e.target.value, record.key)}
          placeholder="أدخل الترجمة"
          style={{
            border: isValid[record.key] === false ? "1px solid red" : undefined,
            color: isValid[record.key] === false ? "red" : undefined,
            textAlign: "right", // Align text to the right
          }}
        />
      ),
    },
    {
      width: 100,
      title: "الكلمة",
      align: "right",
      dataIndex: "arabic_words",
      key: "arabic_words",
      // Align text in the column to the right
      render: (text) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
  ];

  // Check if any field is invalid or empty
  const isButtonDisabled =
    Object.values(inputValues).some((value) => value === "") ||
    Object.values(isValid).includes(false);
  if (LoadingTable) return <Loader />;
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10">
      <HelmetTags title="ترجمه الكلمات | Sir karam makram" />
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          ترجمه الكلمات
        </h2>
      </div>
      {DataTable?.data?.length === 0 ? (
        <div className="w-full text-center">
          <p>لا يوجد ترجمات</p>
        </div>
      ) : LoadingSubmit ? (
        <Loader />
      ) : successSubmit?.data ? (
        <div className="space-y-5">
          <div className="text-center w-full">
            <p className="text-secondary text-3xl">
              النتيجة {successSubmit?.data?.student_degree} /{" "}
              {successSubmit?.data?.category_degree}
            </p>
          </div>
          <Table
            dataSource={successSubmit?.data?.translations}
            columns={Resultcolumns}
            pagination={false}
            className="bg-white shadow-2xl border border-secondary rounded-2xl p-1 md:w-full w-1/2 mx-auto"
          />
        </div>
      ) : (
        <>
          {state?.showTimer && state?.quizInfo?.duration > 0 && (
            <QuizTimer
              onFinishQuiz={onSubmit}
              duration={state?.quizInfo?.duration || 0}
              state={state}
            />
          )}
          <Table
            dataSource={state?.data}
            columns={Translationcolumns}
            loading={LoadingTable}
            pagination={false}
            className="bg-white shadow-2xl border border-secondary rounded-2xl p-1 md:w-full w-1/2 mx-auto"
          />
          <div className="mt-8 flex justify-center">
            <button
              onClick={() => onSubmit()}
              disabled={isButtonDisabled}
              className={`rounded px-4 py-2 text-white ${
                isButtonDisabled
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-secondary hover:bg-accent"
              }`}
            >
              {LoadingSubmit ? "جاري التحميل" : " ارسال الترجمة"}
            </button>
          </div>
        </>
      )}
    </section>
  );
}
