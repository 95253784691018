import { useReducer } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import {
  faArrowRightFromBracket,
  faBookOpenReader,
  faCircleChevronRight,
  faClipboardQuestion,
  faClipboardUser,
  faFilePen,
  faShieldHalved,
  faSchool,
  faSignsPost,
  faUserGear,
  faUserPlus,
  faUsers,
  faUser,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { RiMenuLine, RiUserFollowLine } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";
import logoDark from "../../../assets/logo-dark.png";
import { FaHamburger } from "react-icons/fa";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();

  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }
  return (
    <section className="flex h-screen max-h-screen w-full overflow-hidden bg-white dark:bg-dark dark:text-light md:pt-32">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-32 w-full items-center justify-between bg-secondary px-16 text-light md:flex">
        <div className="logo h-fit">
          <img
            className="h-24 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/dashboard")}
            src="https://api.mrkarammakram.com/uploads/assets/logo-dark.png"
            alt="logoDark"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label
            htmlFor="checkbox"
            className="toggle !flex items-center justify-center"
          >
            <RiMenuLine className="text-6xl" />
          </label>
        </div>
      </section>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto  ">
        <Outlet />
      </section>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-32" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-32" : "w-1/5 "
        } fixed right-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100"
                        : "md:pointer-events-none  md:translate-x-20 md:opacity-0"
                    } md:pt-32`}
      >
        <div className="collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute left-0 top-20 z-[100] -translate-x-1/2 cursor-pointer rounded-full bg-light text-5xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-180"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-between gap-10 overflow-y-auto bg-gray-50 py-10">
          <div className="logo h-fit md:hidden fixed top-0 bg-gray-50 z-50 border-b-2 border-gray-100">
            <img
              className={` ${
                state.collapse ? "h-32" : "h-48"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/")}
              src="https://api.mrkarammakram.com/uploads/assets/logo-dash-light.png"
              alt="logo-dash-light"
            />
          </div>

          <ul className="dash-menu-items flex h-fit w-full  flex-col items-center gap-5 relative top-[100px] mt-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.length > 0) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/dashboard"
                  className={`group flex  h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "justify-bet items-center  px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المنشورات
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className={`w-8`} icon={faSignsPost} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("student")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/students"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الطلاب
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faSchool} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/codes"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الأكواد
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faShieldHalved} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code_tracker")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/codes-tracker"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      تتبع الأكواد
                    </p>
                    <div className="icon">
                      <RiUserFollowLine className="w-8" />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("attendance")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/attendance"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الحضور
                    </p>

                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faClipboardUser} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("lecture")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/lectures"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المحاضرات
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon
                        className="w-8"
                        icon={faBookOpenReader}
                      />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("exams")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/exams"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الإختبارات
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faFilePen} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("translationcategory")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/translations"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      تسميع الكلمات{" "}
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faFilePen} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("questionbank")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/question-bank"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      بنك الأسئلة{" "}
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon
                        className="w-8"
                        icon={faClipboardQuestion}
                      />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("group")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/groups"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المجموعات
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faUsers} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("request")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/requests"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  {NumbersInTabs?.data?.requests_count &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("request_accept")
                      )) && (
                      <span
                        className={`numbers flex items-center  justify-center rounded-full p-1  ${
                          state.collapse
                            ? "mb-5 h-3   w-3  bg-secondary text-[0px]"
                            : "h-10  w-10 min-w-[25px] min-h-[25px]  text-2xl"
                        }  text-secondary transition-all duration-200 ease-in-out group-hover:bg-light amd:mb-5 amd:h-3 amd:w-3  amd:min-w-[8px] amd:min-h-[8px] amd:bg-secondary amd:text-[0px] md:mb-0 md:h-10 md:w-10 md:min-w-[25px] md:min-h-[25px] md:bg-transparent md:text-2xl`}
                      >
                        {NumbersInTabs?.data?.requests_count}
                      </span>
                    )}

                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الطلبات
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faUserPlus} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("user")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/assistants"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      المساعدين
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon className="w-8" icon={faUserGear} />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("whatsapp_show")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/whatsapp"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div
                    className={`text-icon  flex w-full items-center gap-10 ${
                      !state.collapse ? "justify-end" : "gap-10 justify-center"
                    }`}
                  >
                    <p
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      واتساب
                    </p>
                    <div className="icon">
                      <FontAwesomeIcon
                        className="w-8 text-3xl font-bold"
                        icon={faWhatsapp}
                      />
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {/* 
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("file_view")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="flex h-full w-full justify-center"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  to="/admin/files"
                  className={`group flex h-20 w-9/12  alg:w-11/12 ${
                    state.collapse
                      ? "items-center justify-center px-0"
                      : "items-center justify-between px-5"
                  }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
                >
                  <div className={`text-icon  flex w-full items-center gap-10 ${state.collapse ? '': 'gap-10'}`}>
          justify-end          < justify-centerp
                      className={` ${
                        state.collapse ? "hidden" : " inline-block"
                      }`}
                    >
                      الملفات
                    </p>
                    <FontAwesomeIcon
                      className="w-8 text-3xl font-bold"
                      icon={faFolderOpen}
                    />
                  </div>
                </NavLink>
              </li>
            )} */}

            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="flex h-full w-full justify-center"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                to="/admin/profile"
                className={`group flex h-20 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-between px-5"
                }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div
                  className={`text-icon  flex w-full items-center gap-10 ${
                    !state.collapse ? "justify-end" : "gap-10 justify-center"
                  }`}
                >
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    }`}
                  >
                    الملف الشخصي
                  </p>
                  <div className="icon">
                    <FontAwesomeIcon className="w-8" icon={faUser} />
                  </div>
                </div>
              </NavLink>
            </li>
            <li className="log-out flex h-fit  w-full justify-center mb-10">
              <button
                onClick={() => handleSignOut()}
                className={`group flex h-20 w-9/12  alg:w-11/12 ${
                  state.collapse
                    ? "items-center justify-center px-0"
                    : "items-center justify-end pr-5"
                }    rounded-2xl  transition-all duration-200 ease-in-out hover:bg-secondary hover:text-light `}
              >
                <div
                  className={`text-icon  flex w-full items-center gap-10 ${
                    !state.collapse ? "justify-end" : "gap-10 justify-center"
                  }`}
                >
                  <p
                    className={` ${
                      state.collapse ? "hidden" : " inline-block"
                    } `}
                  >
                    تسجيل الخروج
                  </p>
                  <div className="icon">
                    <FontAwesomeIcon
                      className="w-8 rotate-180"
                      icon={faArrowRightFromBracket}
                    />
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AdminLayout;
